import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import ResponsiveSection from '../components/ResponsiveSection';

const NotFoundPage = () => (
  <Layout pagePadding={true}>
    <SEO title="404: Page Not Found" />
    <ResponsiveSection>
      <h1>404 - Not Found</h1>
      <p>The page you're looking for doesn&#39;t exist&nbsp;&nbsp;😢</p>
    </ResponsiveSection>
  </Layout>
);

export default NotFoundPage;
