import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const Loading = ({
  children,
  fontSize = 'inherit',
  height = '100%',
  width = '100%',
}) => {
  if (!children) {
    return (
      <div
        style={{
          height,
          width,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize,
        }}
      >
        <FontAwesomeIcon className="fa-spin" icon={faCircleNotch} />
      </div>
    );
  }
  return <>{children}</>;
};

Loading.propTypes = {
  fontSize: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default Loading;
