import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Button, Input } from 'digital-rabbit-cl';
import { isEmpty, isEmail } from 'validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import ResponsiveSection from '../ResponsiveSection';
import SEO from '../Seo';
import Layout from '../Layout';
import theme from '../../theme';
import ValidationWrapper from '../ValidationWrapper';
import FormLabel from '../FormLabel';
import FormValidator from '../../utils/FormValidator';
import getFirebase from '../../firebase';
const firebase = getFirebase();

const validator = new FormValidator([
  {
    field: 'email', 
    method: (input) => isEmpty(input),
    validWhen: false,
    message: 'Email is required.',
  },
  {
    field: 'email', 
    method: (input) => isEmail(input),
    validWhen: true,
    message: 'Must be a valid email address.',
  },
  {
    field: 'password', 
    method: (input) => isEmpty(input),
    validWhen: false,
    message: 'Password is required.',
  },
]);

const SignIn = () => {
  const initialFormState = {
    email: '',
    password: '',
  };
  const [formState, setFormState] = useState(initialFormState);
  const { email, password } = formState;

  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [validation, setValidation] = useState(validator.valid());
  const [sendingState, setSendingState] = useState(false);

  const handleChange = ({ target }) => {
    const { name, value } = target;

    // re-validate if form has been submitted
    if (submitted) {
      const validationStatus = validator.validate({ ...formState, [name]: value });
      setValidation(validationStatus);
    }

    setFormState(prev => ({ ...prev, [name]: value }));
  };

  const signInWithEmailAndPasswordHandler = async (e) => {
    e.preventDefault();
    setError(false);
    setSendingState(true);
    setSubmitted(true);

    const validationStatus = validator.validate(formState);
    setValidation(validationStatus);
    if (validationStatus.isValid) {
      try {
        if (firebase) await firebase.auth().signInWithEmailAndPassword(email, password);
        else throw new Error();
      } catch (error) {console.log({ error })
        const { code } = error;
        if (
          code === 'auth/wrong-password'
          || code === 'auth/invalid-email'
          || code === 'auth/user-not-found'
        ) setError("Invalid username or password.");
        else if (code === 'auth/user-disabled') setError("Account disabled.");
        else if (code === 'auth/too-many-requests') setError("Too many failed login attempts. Please try again later.");
        else setError("Error signing in. Please try again.");
        setSendingState(false);
      }
    } else {
      setSendingState(false);
    }
  };

  return (
    <Layout hideSignInLink={true} pagePadding={true}>
      <SEO title="Sign In" />
      <ResponsiveSection>
        <h1 style={{ marginBottom: `calc(${theme.fonts.rhythm} / 2)` }}>Sign In</h1>
        <div>
          {
            error && (
              <div style={{ color: theme.colors.error, display: 'flex', alignItems: 'center', padding: '0 0 1em' }}>
                <FontAwesomeIcon icon={faExclamationCircle} style={{ fontSize: '1.5em', marginRight: '.5em' }} />
                <b>{error}</b>
              </div>
            )
          }
          <form onSubmit={signInWithEmailAndPasswordHandler} style={{ maxWidth: '400px' }}>
            <ValidationWrapper validation={validation.email}>
              {
                (error) => (
                  <>
                    <FormLabel
                      name="Email"
                      labelFor="email"
                      style={{
                        color: error ? theme.colors.error : theme.colors.primary,
                        opacity: sendingState ? .3 : 1,
                      }}
                    />
                    <Input
                      id="email"
                      name="email"
                      color={error ? theme.colors.error : theme.colors.primary}
                      disabled={sendingState}
                      value={email}
                      placeholder="example@domain.com"
                      placeholderOpacity={0.5}
                      onChange={handleChange}
                    />
                  </>
                )
              }
            </ValidationWrapper>
            <ValidationWrapper validation={validation.password}>
              {
                (error) => (
                  <>
                    <FormLabel
                      name="Password"
                      labelFor="password"
                      style={{
                        color: error ? theme.colors.error : theme.colors.primary,
                        opacity: sendingState ? .3 : 1,
                      }}
                    />
                    <Input
                      id="password"
                      name="password"
                      type="password"
                      color={error ? theme.colors.error : theme.colors.primary}
                      disabled={sendingState}
                      value={password}
                      placeholder="Your Password"
                      placeholderOpacity={0.5}
                      onChange={handleChange}
                    />
                  </>
                )
              }
            </ValidationWrapper>
            <Button
              type="submit"
              color={theme.fonts.color}
              hoverColor={theme.colors.primary}
              hoverTextColor={theme.colors.secondary}
              disabled={sendingState || !validation.isValid}
              outline
            >
              {
                sendingState
                  ? (
                    <>
                      <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
                      &nbsp;Sending...
                    </>
                  ) : `Sign in`
              }
            </Button>
          </form>
          <p>
            <Link to="/dashboard/password-reset" style={{ color: 'white' }}>
              Forgot Password?
            </Link>
          </p>
        </div>
      </ResponsiveSection>
    </Layout>
  );
};

export default SignIn;