import React, { useContext } from "react";
import SignIn from './SignIn';

import { UserContext } from '../../providers/UserProvider';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const user = useContext(UserContext);
  if (!user) return <SignIn />;
  return <Component {...rest} />;
};

export default PrivateRoute;