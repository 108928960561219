import React from 'react';
import Layout from '../Layout/Dashboard';
import SEO from '../Seo';
import marketing from '../../../content/marketing';
import buildULTree from '../../utils/buildULTree';

const {
  channels,
  competitors,
  marketingPersonas,
  propositionStatement,
  products,
  goals,
} = marketing;

function MarketingPage() {
  return (
    <Layout>
      <SEO title="Dashboard" />
      <h1>Marketing Plan</h1>
      <ol type="I">
        <li>
          What are we marketing?
          <ul>
            {products.map(product => <li key={product}>{product}</li>)}
          </ul>
        </li>
        <li>
          Who are we marketing it to?
          {
            marketingPersonas.map(({ Name, ...persona }) => (
              <ul key={Name}>
                <li>
                  <b>{Name}</b>
                  <ul style={{ listStyle: 'none' }}>{buildULTree(persona)}</ul>
                </li>
              </ul>
            ))
          }
        </li>
        <li>
          How are we marketing it?
          <ul>
            <li>
              <b>Positioning Statement -</b> "{propositionStatement}"
            </li>
            <li>Clean, simple, powerful</li>
            <li>We are a small company looking to build a portfolio filled with great companies/clients. We do things the right way. We spend a little more time, but we build the house out of bricks. Save money down the line, it’s easier to add to a solid foundation, easier to maintain</li>
            <li>Helpful tone, not overly sales oriented</li>
            <li>Using Industry Standards</li>
            <li>Look at these companies: https://www.goodfirms.co/directory/languages/top-software-development-companies/javascript</li>
            <li>
              Competitors
              <ul>
                {competitors.map(competitor => <li key={competitor}>{competitor}</li>)}
              </ul>
            </li>
            <li>
              Channels
              <ul>
                {channels.map(channel => <li key={channel}>{channel}</li>)}
              </ul>
            </li>
          </ul>
        </li>
        <li>
          Why?
          <li>Mission Statement</li>
          <li>Philosophy</li>
          <li>Core Values</li>
        </li>
        <li>
          What does success look like?
          <ul>
            <li>
              Goals/KPIs & Timeframe
              <ul style={{ listStyle: 'none' }}>
                {
                  goals.map(({ Name, number, per, disabled }) => {
                    return (
                      <li
                        key={Name}
                        style={{
                          fontStyle:  disabled ? 'italic' : 'normal',
                          opacity: disabled ? '0.5' : '1',
                        }}
                      >
                        <b>{Name}: </b>{number} per {per}
                      </li>
                    );
                  })
                }
              </ul>
            </li>
          </ul>
        </li>
      </ol>
    </Layout>
  );
}

export default MarketingPage;
