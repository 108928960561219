import React from 'react';

import theme from '../../theme.js';
import GlobalStyle from '../../globalStyle';
import SideBar from '../dashboard/SideBar';
import UserAuthMenu from '../dashboard/UserAuthMenu';

const Layout = ({ children }) =>(
  <div style={{ display: 'flex' }}>
    <GlobalStyle />
    <SideBar />
    <div
      style={{
        backgroundColor: theme.colors.primary,
        color: theme.colors.secondary,
        flex: '1 1 auto',
        padding: '.5rem',
      }}
    >
      <UserAuthMenu />
      {children}
    </div>
  </div>
);

export default Layout;
