import { useState, useEffect } from 'react';

function fetchCFMailerMessages() {
  const controller = new AbortController();
  const signal = controller.signal;

  const promise = fetch(`https://app.digitalrabb.it/api/v1/contact-form`, { method: 'GET', signal })
    .then((res) => {
      if (res.ok) return res.json();
      throw new Error('Error fetching messages');
    });
    
  promise.cancel = () => controller.abort();

  return promise;
}

export default function useCFMailerMessages() {
  const [status, setStatus] = useState('fetching');
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const cFMailerFetchPromise = fetchCFMailerMessages();

    cFMailerFetchPromise
      .then((data) => {
        setData(data);
        setStatus('success');
      })
      .catch((err) => {
        setStatus('error');
        setError(err);
      });

    return () => {
      cFMailerFetchPromise.cancel();
    };
  }, []);

  return {
    status,
    error,
    data,
  };
};
