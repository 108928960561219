import React from 'react';
import SEO from '../Seo';
import Layout from '../Layout/Dashboard';
import sales from '../../../content/sales';
import buildULTree from '../../utils/buildULTree';

const { goals, elevatorPitch, pipeline } = sales;

function SalesPage() {
  return (
    <Layout>
      <SEO title="Dashboard" />
      <h1>Sales</h1>
      <ol type="I">
        <li>
          Elevator Pitch
          <ul>{elevatorPitch.map((point) => <li>{point}</li>)}</ul>
        </li>
        <li>
          Sales Pipeline
          <ul style={{ listStyle: 'none' }}>{buildULTree(pipeline)}</ul>
        </li>
        <li>
          Goals/KPIs & Timeframe
          <ul style={{ listStyle: 'none' }}>
            {
              goals.map(({ Name, number, per, disabled }) => {
                return (
                  <li
                    key={Name}
                    style={{
                      fontStyle:  disabled ? 'italic' : 'normal',
                      opacity: disabled ? '0.5' : '1',
                    }}
                  >
                    <b>{Name}: </b>{number} per {per}
                  </li>
                );
              })
            }
          </ul>
        </li>
      </ol>
    </Layout>
  );
}

export default SalesPage;
