import React, { useContext } from 'react';
import { Redirect, Router } from '@reach/router';

import { UserContext } from '../providers/UserProvider';
import PrivateRoute from '../components/dashboard/PrivateRoute';
import SignIn from '../components/dashboard/SignIn';
import Home from '../components/dashboard/Home';
import PasswordReset from '../components/dashboard/PasswordReset';
import TimeClock from '../components/dashboard/TimeClock';
import ContactFormMailer from '../components/dashboard/ContactFormMailer';
import Marketing from '../components/dashboard/Marketing';
import Sales from '../components/dashboard/Sales';
import OKRs from '../components/dashboard/OKRs';
import NotFound from './404';
import Loading from '../components/Loading';
import theme from '../theme';

const SignInRedirect = () => (
  useContext(UserContext)
    ? <Redirect to="/dashboard" noThrow />
    : <SignIn />
);

const Dashboard = () => {
  if (useContext(UserContext) === undefined) return <Loading fontSize={`calc(${theme.fonts.size} * 3)`} height="100vh" />;
  return (
    <Router basepath="/dashboard">
      <SignInRedirect path="/sign-in" />
      <PasswordReset path="/password-reset" />
      <PrivateRoute path="/time-clock" component={TimeClock} />
      <PrivateRoute path="/contact-form-mailer" component={ContactFormMailer} />
      <PrivateRoute path="/marketing" component={Marketing} />
      <PrivateRoute path="/sales" component={Sales} />
      <PrivateRoute path="/OKRs" component={OKRs} />
      <PrivateRoute path="/" component={Home} />
      <NotFound default />
    </Router>
  );
};

export default Dashboard;
