import React from 'react';
import Layout from '../Layout/Dashboard';
import SEO from '../Seo';
import OKRs from '../../../content/okrs';
import buildULTree from '../../utils/buildULTree';

function MarketingPage() {
  return (
    <Layout>
      <SEO title="Dashboard" />
      <h1>OKRs</h1>
      <ol type="I">
        {buildULTree(OKRs)}
      </ol>
    </Layout>
  );
}

export default MarketingPage;
