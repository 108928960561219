import React from 'react';
import { Link } from 'gatsby';
import SEO from '../Seo';
import Layout from '../Layout/Dashboard';

const Home = () => (
  <Layout>
    <SEO title="Dashboard" />
    <h1>To Do</h1>
    <ul>
      <li>
        <Link to="/dashboard/sales">Sales</Link>
        <ul>
          <li style={{ fontStyle: 'italic', opacity: '0.5', textDecoration: 'line-through' }}>Set Goals &amp; KPIs</li>
          <li style={{ fontStyle: 'italic', opacity: '0.5', textDecoration: 'line-through' }}>Sales Pipeline</li>
          <li>Proposal/Contract Templates for all products</li>
          <li>Start reaching out to potential customers</li>
        </ul>
      </li>
      <li>
        <Link to="/dashboard/marketing">Marketing</Link>
        <ul>
          <li style={{ fontStyle: 'italic', opacity: '0.5', textDecoration: 'line-through' }}>Set Goals &amp; KPIs</li>
          <li>Write Positioning Statement</li>
          <li>Create email series</li>
          <li>
            Research Competitors (How is Digital Rabbit superior)
            <ul>
              <li>Excel</li>
              <li>Tableau, Power BI</li>
              <li>Chartio</li>
              <li>Google Data Studio</li>
              <li>Holistics</li>
            </ul>
          </li>
          <li>Marketing Personas</li>
          <li>
            Portfolio
            <ul>
              <li>COVID-19 app</li>
              <li>Case Studies for Contracting Clients</li>
              <li>
                Data Playground (Random GIS visualizations, types of charts etc)
              </li>
              <li>Stock Portfolio Manager</li>
              <li>Social Media Sentiment Analysis for stocks?</li>
              <li>Covid stock market effects? <a href="https://www.marketwatch.com/graphics/k-shaped-recovery/">Marketwatch Charts</a></li>
              <li>Templates (data vis, GIS, app, website)</li>
              <li>Small business sites</li>
              <li>Election results map</li>
              <li>Time Clock App</li>
              <li>Taskula</li>
              <li>Politician Donor Visualization (Like Nascar)</li>
            </ul>
          </li>
          <li>Testimonials</li>
        </ul>
      </li>
    </ul>
  </Layout>
);

export default Home;
