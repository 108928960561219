import React from 'react';

function buildULTree(node) {
  return (
    Object
      .entries(node)
      .map(([key, value]) => {
        if (!isNaN(key)) {
          return <li key={key} style={{ listStyle: 'circle' }}>{value}</li>;
        }
        if (typeof value === 'string' || typeof value === 'number') {
          return (
            <li key={key}>
              <b>{key}</b>: {value}
            </li>
          );
        }
        return (
          <li key={key}>
            <b>{key}</b>
            <ul style={{ listStyle: 'none' }}>{buildULTree(value)}</ul>
          </li>
        );
      })
  );
}

export default buildULTree;
