import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import theme from '../../theme';

const NavItem = styled.li`
  font-weight: bold;
  margin: 0;
  a {
    color: ${theme.colors.primary};
    display: block;
    padding: .45rem 1rem;
    text-decoration: none;
    &:hover,
    &.active {
      background-color: white;
      color: black;
      opacity: .5;
    }
  }
`;

const SideBarHeader = styled.div`
  padding: ${theme.layout.wrapperPaddingSm};

  @media screen and (min-width: ${theme.breakpoints.sm}) {
    padding: ${theme.layout.wrapperPadding};
  }
`;

const BrandImageDiv = styled.div`
  background-image: url(/logo-light.svg);
  background-repeat: no-repeat;
  height: 2.25rem;
  width: 10rem;

  @media screen and (min-width: ${theme.breakpoints.sm}) {
    height: 3rem;
  }
`;

const SideBar = () => (
  <div
    style={{
      backgroundColor: theme.colors.secondary,
      flex: '0 0 auto',
      height: '100vh',
      position: 'sticky',
      top: 0,
      width: '300px',
    }}
  >
    <Link
      to="/"
      style={{ textDecoration: `none` }}
    >
      <SideBarHeader>
        <BrandImageDiv />
      </SideBarHeader>
    </Link>
    <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
      <NavItem>
        <Link activeClassName="active" to="/dashboard/">Home</Link>
      </NavItem>
      <NavItem>
        <Link activeClassName="active" to="/dashboard/OKRs">OKRs</Link>
      </NavItem>
      <NavItem>
        <Link activeClassName="active" to="/dashboard/sales">Sales</Link>
      </NavItem>
      <NavItem>
        <Link activeClassName="active" to="/dashboard/marketing">Marketing</Link>
      </NavItem>
      <NavItem>
        <Link activeClassName="active" to="/dashboard/time-clock">Time Clock</Link>
      </NavItem>
      <NavItem>
        <Link activeClassName="active" to="/dashboard/contact-form-mailer">Contact Form Usage</Link>
      </NavItem>
    </ul>
  </div>
);

export default SideBar;
