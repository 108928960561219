import React, { useContext } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import { UserContext } from '../../providers/UserProvider';
import getFirebase from '../../firebase';
const firebase = getFirebase();

const UnstyledButton = styled.button`
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
  margin: 0;
  padding: 0;

  &:focus,
  &:hover {
    outline: none;
  }

  &:hover {
    opacity: .75;
  }
`;

const UserAuthMenu = () =>  {
  const user = useContext(UserContext);

  return (
    <div style={{ textAlign: 'right' }}>
      {user && (
        <UnstyledButton
          style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
          }}
          onClick={() => firebase && firebase.auth().signOut()}
        >
          <b>
            <FontAwesomeIcon icon={faSignOutAlt} />
            {' '}
            Sign Out
          </b>
        </UnstyledButton>
      )}
    </div>
  );
}

export default UserAuthMenu;
