import React, { useEffect, useState } from 'react';
import { Button } from 'digital-rabbit-cl';
import Layout from '../Layout/Dashboard';
import SEO from '../Seo';

const TimeClock = () => {
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());

  useEffect(() => {
    const clock = setInterval(() => setCurrentTime(new Date().toLocaleTimeString()), 1000);

    return function cleanup() {
      clearInterval(clock);
    }
  }, []);

  return (
    <Layout>
      <SEO title="Dashboard" />
      <h1 style={{ textAlign: 'center' }}>{currentTime}</h1>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={() => alert('punching in')}>
          Punch In
        </Button>
      </div>
    </Layout>
  );
};

export default TimeClock;
