import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input } from 'digital-rabbit-cl';
import { isEmpty, isEmail } from 'validator';
import {
  faCheckCircle,
  faExclamationCircle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import theme from '../../theme';
import SEO from '../Seo';
import ValidationWrapper from '../ValidationWrapper';
import FormValidator from '../../utils/FormValidator';
import Layout from '../Layout';
import ResponsiveSection from '../ResponsiveSection';
import getFirebase from '../../firebase';
const firebase = getFirebase();

const validator = new FormValidator([
  {
    field: 'email', 
    method: (input) => isEmpty(input),
    validWhen: false,
    message: 'Email is required.',
  },
  {
    field: 'email', 
    method: (input) => isEmail(input),
    validWhen: true,
    message: 'Must be a valid email address.',
  },
]);

const PasswordReset = () => {
  const initialFormState = {
    email: '',
  };
  const [formState, setFormState] = useState(initialFormState);
  const { email } = formState;

  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [validation, setValidation] = useState(validator.valid());
  const [sendingState, setSendingState] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = ({ target }) => {
    const { name, value } = target;

    // re-validate if form has been submitted
    if (submitted) {
      const validationStatus = validator.validate({ ...formState, [name]: value });
      setValidation(validationStatus);
    }

    setFormState(prev => ({ ...prev, [name]: value }));
  };

  const sendResetEmail = async (e) => {
    e.preventDefault();
    setError(false);
    setSendingState(true);
    setSubmitted(true);

    const validationStatus = validator.validate(formState);
    setValidation(validationStatus);
    if (validationStatus.isValid) {
      try {
        if (firebase) await firebase.auth().sendPasswordResetEmail(email);
        else throw new Error();
        setError(false);
        setSuccess(true);
      } catch (error) {
        setError("Error resetting password. Please try again.");
      }
    }

    setSendingState(false);
  };

  return (
    <Layout pagePadding={true}>
      <SEO title="Password Reset" />
      <ResponsiveSection>
        {
          success
            ? (
              <div style={{ color: theme.colors.success, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '1.5em', marginRight: '.5em' }} />
                <div>
                  A password reset link has been sent to you email address!
                </div>
              </div>
            ) : (
              <>
                <h1>
                  Password Reset
                </h1>
                <p>
                  Enter your email address to reset your password.
                </p>
                <div style={{ maxWidth: '400px' }}>
                  <form onSubmit={sendResetEmail}>
                    {
                      error && (
                        <div style={{ color: theme.colors.error, display: 'flex', alignItems: 'center', padding: '0 0 1em' }}>
                          <FontAwesomeIcon icon={faExclamationCircle} style={{ fontSize: '2em', marginRight: '.5em' }} />
                          <b>{error}</b>
                        </div>
                      )
                    }
                    <ValidationWrapper validation={validation.email}>
                      {
                        (error) => (
                          <>
                            <Input
                              id="email"
                              name="email"
                              type="email"
                              color={error ? theme.colors.error : theme.colors.primary}
                              disabled={sendingState}
                              value={email}
                              placeholder="example@domain.com"
                              placeholderOpacity={0.5}
                              onChange={handleChange}
                            />
                          </>
                        )
                      }
                    </ValidationWrapper>
                    <Button
                      type="submit"
                      color={theme.fonts.color}
                      hoverColor={theme.colors.primary}
                      hoverTextColor={theme.colors.secondary}
                      disabled={sendingState || !validation.isValid}
                      outline
                    >
                      {
                        sendingState
                          ? (
                            <>
                              <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
                              &nbsp;Sending...
                            </>
                          ) : `Reset Password`
                      }
                    </Button>
                  </form>
                </div>
              </>
            )
        } 
      </ResponsiveSection>
    </Layout>
  );
};

export default PasswordReset;
