import React from 'react';
import Layout from '../Layout/Dashboard';
import Table from '../Table';
import useCFMailerMessages from '../../utils/useCFMailerMessages';
import Loading from '../Loading';
import theme from '../../theme';

function ContactFormMailer() {
  const {
    status,
    error,
    data
  } = useCFMailerMessages();

  if (error) return 'Something went wrong';
  if (status === 'fetching') return <Loading fontSize={`calc(${theme.fonts.size} * 3)`} />;
  return <Table data={data} />;
}

function ContactFormMailerPage() {
  return (
    <Layout>
      <ContactFormMailer />
    </Layout>
  );
}

export default ContactFormMailerPage;
